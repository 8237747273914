// core overrides

// Navbar

$navbar-brand-width: 200px;
$navbar-brand-logo: url('../images/logo.svg');
// $navbar-brand-logo: '';
$navbar-brand-logo-size: 170px auto;
$navbar-active-color: map-get($theme-colors, info);

$navbar-brand-minimized-logo: url('../images/logo-symbol.svg');
// $navbar-brand-minimized-logo: '';
$navbar-brand-minimized-logo-size: 36px;

// Sidebar

$sidebar-width: $navbar-brand-width;
$sidebar-bg: theme-color('primary');
$sidebar-color: fade-out($white, .3);

// Sidebar Navigation

$sidebar-nav-color: fade-out($white, .3);
$sidebar-nav-title-color: $white;
$sidebar-nav-title-padding-y: 1.3rem;
$sidebar-nav-title-font-size: 1rem / 16 * 14;
$sidebar-nav-link-color: $sidebar-nav-color;
$sidebar-nav-link-icon-color: fade-out($white, .7);

$sidebar-nav-link-hover-color: $sidebar-nav-link-color;
$sidebar-nav-link-hover-bg: lighten($sidebar-bg, 5%);
$sidebar-nav-link-hover-icon-color: fade-out($white, .7);

$sidebar-nav-link-active-color: $sidebar-nav-link-color;
$sidebar-nav-link-active-bg: $wf-royal-light-blue;
$sidebar-nav-link-active-icon-color: fade-out($white, .7);

$sidebar-nav-dropdown-color: $sidebar-nav-link-color;
$sidebar-nav-dropdown-bg: $wf-royal-dark-blue;
$sidebar-nav-dropdown-indicator-color: $sidebar-nav-dropdown-color;
$sidebar-nav-dropdown-indicator-hover-color: $sidebar-nav-link-hover-color;

// Sidebar Minimizer

$sidebar-minimizer-bg: $wf-royal-dark-blue;
$sidebar-minimizer-hover-bg: darken($wf-royal-dark-blue, 2%);
